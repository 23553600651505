.wd {
  width: 100%;
  margin-bottom: var(--margin-block-big);
  padding-inline: var(--padding-inline-ten-percent);
}

.wd-crucial-over {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-column-gap: calc(var(--gap) * 0.5);
  grid-row-gap: calc(var(--gap) * 2);
  margin-top: calc(var(--gap) * 2);
  place-content: center;
}

.wd-crucial {
  display: grid;
  grid-gap: calc(var(--gap) * 0.25);
  width: 100%;
}

.wd-crucial h1 {
  width: fit-content;
  font-size: clamp(var(--clamp-medium-text));
}

.wd-crucial h3 {
  background-color: var(--red);
  color: var(--white);
  border-radius: var(--border-radius);
  padding: 0.25rem 0.5rem;
  font-size: clamp(var(--clamp-small-text));
}

.wd-line {
  background-color: var(--red);
  height: 4px;
}

.wd-stats {
  margin-bottom: calc(var(--gap) * 1.25);
  width: min(100%, 800px);
  border-bottom: solid 3px var(--form-grey);
  padding-bottom: calc(var(--gap) * 0.5);
}

.wd-stats span {
  color: var(--red);
}

.wd-stats:first-of-type {
  margin-top: calc(var(--gap) * 2);
}

.change-back {
  background-image: url("../files/landscape.jpg");
  margin-block: calc(var(--gap) * 2);
  overflow: hidden;
  padding-inline: 0;
  margin-inline: 0;
  box-sizing: border-box;
}

.wd .ns-inner-text {
  width: 100%;
}

.wd .ns-inner-text p {
  border-radius: 0;
}

.wd .last-projects {
  padding-inline: 0;
  margin-top: calc(var(--gap) * 2.5);
}

.wd h4 {
  width: 100%;
  background-color: var(--yellow);
  padding: 1.25rem;
  border-radius: var(--border-radius);
  margin-top: calc(var(--gap) * 2);
}

@media (max-width: 401px) {
  .wd-crucial-over {
    grid-template-columns: 1fr;
    padding-inline: 0;
  }
}
