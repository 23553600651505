.contact-map {
  width: 100%;
  padding-inline: var(--padding-inline-ten-percent);
}

.google-math-map {
  width: 100%;
  height: 500px;
  border: 0;
  outline: 0;
  filter: drop-shadow(var(--drop-shadow-intro-images));
  border-radius: var(--border-radius);
  margin-top: calc(var(--gap) * 2);
}
