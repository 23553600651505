.new-standards {
  width: 100%;
  margin-bottom: var(--margin-block-big);
  height: fit-content;
  box-sizing: border-box;
}

.new-standards h3 {
  font-size: clamp(var(--clamp-medium-text));
  padding-inline: var(--padding-inline-ten-percent);
  margin-bottom: var(--gap);
}

.ns-inner {
  /*   background-image: url("../files/universe.jpeg");
 */
  /*  background-color: rgba(216,32,32,.9);
 */
  background-size: 99.9%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: grid;
  grid-gap: calc(var(--gap) * 2);
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  padding-inline: var(--padding-inline-ten-percent);
  filter: drop-shadow(var(--drop-shadow-lighter));
}

.ns-inner-text {
  display: grid;
  grid-gap: var(--gap);
  place-content: center;
  place-items: center;
  text-align: center;
  padding-block: var(--gap);
}

.ns-inner-text > * {
  filter: drop-shadow(var(--drop-shadow-white));
}

.ns-inner-text h1 {
  font-size: clamp(var(--clamp-big-text));
}

.ns-inner-text h3 {
  font-size: clamp(var(--clamp-medium-text));
  background-color: rgba(171, 171, 171, 0.4);
  color: var(--darkgrey);
  padding: 5px 16px;
  box-sizing: border-box;
  word-spacing: 9999px;
  border-radius: var(--border-radius);
}

.ns-inner-text span {
  width: 75%;
  margin-inline: auto;
  height: 5px;
  background-color: var(--red);
  padding: 0;
  margin-block: 0;
  border-radius: var(--border-radius);
}

.ns-inner-text p {
  font-size: clamp(var(--clamp-small-text));
  background-color: rgba(0, 0, 0, 0.4);
  color: var(--white);
  padding: 5px 16px;
  border-radius: var(--border-radius);
  filter: drop-shadow(var(--drop-shadow-lighter));
}

@media (max-width: 725px) {
  .ns-inner {
    grid-template-columns: 1fr;
    background-size: 99.9% 99.9%;
  }
}
