.introduction {
  width: 100%;
  margin-top: 120px;
  padding-inline: var(--padding-inline-ten-percent);
}

.introduction-list-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 30px;
  column-gap: 30px;
  margin-block: 40px;
  text-align: center;
  margin-right: 0%;
  box-sizing: border-box;
  /*   background-color: rgba(251, 235, 99, 0.5);
 */
  padding: 1rem;
  border-radius: var(--border-radius);
}

.introduction-list-images {
  gap: calc(var(--gap) / 2);
  font-size: clamp(var(--clamp-small-text));
  font-weight: bold;
  transition: var(--transition-long);
}

.introduction-list-images img {
  height: 225px;
  aspect-ratio: 1/1;
  user-select: none;
  border-radius: var(--border-radius);
  margin-bottom: 20px;
  transition: var(--transition-long);
  object-fit: cover;
  filter: drop-shadow(0 0 1rem);
}

.introduction-list-images img {
  border-radius: 50%;
}

.introduction-list-images img.radius {
  border-radius: 10px;
  filter: drop-shadow(0 0 0rem);
  width: min(90%, 280px);
}

.introduction-list-images:hover.introduction-list-images img {
  filter: grayscale(1) drop-shadow(var(--drop-shadow-lighter)) blur(0.5px)
    saturation(90deg);
}

.introduction-list-images img.no-cover {
  object-fit: contain;
}

.long-line {
  width: min(100%, 600px);
  width: 100%;
  height: 8px;
  /* background-image: linear-gradient(to right, var(--gradient)); */
  /*   background-image: linear-gradient(var(--black) 50%, var(--red) 50%);
 */ /*   background-image: url("../files/universe.jpeg");
 */
  background-color: var(--darkgrey);
  background-size: 150%;
  background-position: 40% 40%;
  animation: moveStars 8s linear infinite alternate;
  border-radius: var(--border-radius);
  display: none; /* Change it and add on in next version after solving the blog */
}

@keyframes moveStars {
  to {
    background-position: 80% 80%;
  }
}

.inner-slider2 {
  display: grid;
  grid-gap: var(--gap);
  place-items: center;
  color: var(--black);
  font-weight: bold;
}

.icon-setter {
  transform: scale(2.25);
  color: var(--red);
  fill: var(--red);
  stroke: var(--red);
}

.bckg-red {
  background-color: var(--red);
  background-color: transparent;
  margin-inline: auto;
}

@media (max-width: 500px) {
  .introduction-list-box {
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    margin-right: 0;
    gap: var(--gap);
  }
}
