.last-projects {
  width: 100%;
  padding-inline: var(--padding-inline-ten-percent);
  margin-bottom: var(--margin-block-big);
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap) * 1.5);
  overflow: hidden;
}

.last-projects h1 {
  font-size: clamp(var(--clamp-medium-text));
}

.last-projects p {
  font-size: var(--clamp-small-text);
  width: min(100%, 800px);
}

.project-logos {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  animation: moveImages 5s ease-in-out infinite;
}

@keyframes moveImages {
  0% {
    margin-right: 0;
  }

  100% {
    margin-right: 100%;
  }
}

.project-logos img {
  width: 300px;
  height: 150px;
  margin-right: calc(var(--gap) * 2);
  user-select: none;
  padding-bottom: var(--gap);

  filter: grayscale(1);
  transition: var(--transition-short);
  opacity: 0.9;
}

.project-logos img:hover {
  opacity: 0.35;
}

@media (max-width: 725px) {
  .project-logos img {
    width: 120px;
  }
}
