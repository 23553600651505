@font-face {
  font-family: StandardFont;
  src: url("./files/OldStandardTT-Regular.ttf") format("ttf");
}

html {
  scroll-behavior: smooth;
}

:root {
  /* Colours */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --red: #d82020;
  --white: white;
  --black: #111111;
  --grey: grey;
  --darkgrey: rgb(50, 50, 50);
  --form-grey: rgb(221, 221, 221);
  --lightred: #e34f63;

  --yellow: #fbeb63;
  --gold: #e49216;
  --pink: #de1e91;
  --purple: #b839cf;
  --blue: #135ccb;
  --sea: #4a939a;
  --green: #4eada2;

  --gradient: var(--yellow), var(--gold), var(--red), var(--pink), var(--purple),
    var(--blue), var(--sea), var(--green);

  /* Font sizes */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --big-web-text: 7.25rem;
  --big-mobile-text: 3.68rem;
  --clamp-big-text: 2.15rem, 4vw, 4.5rem;

  --medium-web-text: 3.4rem;
  --medium-mobile-text: 2.5rem;
  --clamp-medium-text: 2.5rem, 5vw, 3.4rem;
  --clamp-medium-header-text: 1.35rem, 5vw, 2rem;
  --clamp-before-text: 2rem, 5vw, 3rem;
  --clamp-red-text: 1.2rem, 4vw, 2rem;

  --small-web-text: 1.2rem;
  --small-mobile-text: 1.1rem;
  --clamp-small-text: 1rem, 2vw, 1.25rem;

  --clamp-xs-text: 0.8rem, 1.5vw, 0.95rem;
  --clamp-cursive-text: 5rem, 12.5vw, 9rem;

  /* Border radius */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --border-radius: 10px;

  /* Drop and box shadow */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --drop-shadow: 0 0 30px var(--black);
  --drop-shadow-lighter: 0 0 8px var(--darkgrey);
  --drop-shadow-white: 0 0 30px rgb(235, 235, 235);
  --drop-shadow-grey: 0 0 30px silver;
  --drop-shadow-intro-images: 0 0 18px rgb(160, 160, 160);

  /* Transition */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --transition-long: all 1.2s cubic-bezier(0.08, 1.16, 0.63, 1.13);
  --transition-middle: all 0.8s cubic-bezier(0.08, 1.16, 0.63, 1.13);
  --transition-short: all 0.4s cubic-bezier(0.08, 1.16, 0.63, 1.13);

  /* Distances, paddings and margins */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --padding-inline-five-percent: 5%;
  --padding-inline-ten-percent: 10%;
  --padding-inline-twenty-percent: 20%;
  --margin-block-big: 150px;
  --gap: 40px;

  /* Fonts */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --standard-font: StandardFont;
  --cursive-font: "Great Vibes", cursive;

  /* NAVIGATION and SPREAD MENU */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --spread-menu-size-50px: 50px;
  --spread-menu-size-40px: 40px;
  --menu-ball-border: var(--white) solid 2px;
  --spreaded-content-border: 10px solid var(--red);
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: var(--standard-font);
  font-size: clamp(var(--clamp-small-text));

  background-image: url("./files/snowing.jpeg");
  background-size: 100% 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: rgb(175, 175, 175);
  background-blend-mode: lighten;
}

body > h1,
body > h2,
body > h3,
body > h4,
body > h5,
body > h6 {
  letter-spacing: -1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::selection {
  background-color: var(--red);
  color: var(--white);
}

::-webkit-scrollbar {
  border-left: 1px solid silver;
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--red);
  background-color: #cccccc;
}

::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: var(--border-radius);
  width: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--red);
}

@media (max-width: 600px) {
  :root {
    --padding-inline-ten-percent: 5%;
  }
}