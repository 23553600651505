.blog {
  width: 100%;
  overflow: hidden;
}

.blog-restricted {
  margin-inline: var(--padding-inline-ten-percent);
  height: 100%;
  background-color: var(--yellow);
  color: var(--darkgrey);
  display: grid;
  place-content: center;
  place-items: center;
  border-radius: var(--border-radius);
  margin-bottom: var(--margin-block-big);
}

.blog-restricted p {
  font-weight: bold;
  font-size: clamp(var(--clamp-medium-text));
  text-align: center;
  padding: var(--padding-inline-ten-percent);
}

.blog-let-us-know {
  margin-bottom: var(--margin-block-big);
  margin-inline: var(--padding-inline-ten-percent);
  position: relative;
  width: min(80%, 750px);
}

.blog-let-us-know a {
  text-decoration: none;
  color: var(--white);
  background-color: var(--red);
  border-radius: var(--border-radius);
  padding-inline: 0.5rem;
  line-height: 1.6;
  transition: var(--transition-middle);
  box-decoration-break: clone;
}

.blog-let-us-know a:hover {
  background-color: var(--darkgrey);
}

.blog-let-us-know::after,
.blog-let-us-know::before {
  content: "";
  position: absolute;
  position: absolute;
  left: 0;
  width: 50%;
  height: 5px;
  transform: scaleX(0);
}

.blog-let-us-know::after {
  bottom: -25px;
  background-color: var(--red);
  animation: shakeLines 1s cubic-bezier(0.95, 0.05, 0.05, 0.95) infinite;
}

.blog-let-us-know::before {
  bottom: -35px;
  background-color: var(--darkgrey);
  animation: shakeLines 1s cubic-bezier(0.95, 0.05, 0.05, 0.95) 0.35s infinite;
  width: 40%;
}

@keyframes shakeLines {
  0% {
    transform: scaleX(0);
    opacity: 0.01;
    transform-origin: center;
  }

  100% {
    transform: scaleX(1);
    opacity: 0.2;
    transform-origin: 300%;
  }
}

.warn-icon-blog {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: calc(var(--gap) * 3);
  transform: scale(7);
  color: var(--red);
}

.modal-warn {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(500px, 80%);
  background-color: var(--white);
  border-radius: var(--border-radius);
  border: 8px solid var(--red);
  outline: 4px solid var(--darkgrey);
  color: var(--darkgrey);
  user-select: none;
  padding: 3rem;
  text-align: center;
  z-index: 1000;
  font-weight: bold;
  line-height: 1.3;
  display: none;
}

.modal-warn img {
  border-radius: 50%;
  width: min(300px, 60%);
}

.show-modal {
  display: block;
}
