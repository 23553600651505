.mathscript-form {
  padding-inline: var(--padding-inline-ten-percent);
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap) * 0.35);
  font-family: var(--standard-font);
}

.mathscript-form input,
.mathscript-form select,
.mathscript-form textarea {
  border: 0;
  outline: 0;
  border-bottom: solid 2px var(--form-grey);
  font-size: clamp(var(--clamp-small-text));
  font-family: var(--standard-font);
}

.mathscript-form input,
.mathscript-form select {
  width: min(100%, 550px);
}

.mathscript-form select {
  margin-top: calc(var(--gap) * 1.5);
  background-color: var(--form-grey);
  border-radius: var(--border-radius);
  padding: 0.75rem;
}

.mathscript-form textarea {
  resize: none;
  height: 300px;
  background-color: var(--form-grey);
  border-radius: var(--border-radius);
  padding: 0.75rem;
  position: relative;
  top: 60px;
}

.mathscript-form input {
  padding: 0.25rem 0.75rem;
  height: fit-content;
  accent-color: var(--red);
}

.form-h3 {
  padding-inline: 0;
  margin: 0;
  margin-bottom: var(--gap);
  font-size: clamp(var(--clamp-medium-text));
}

.form-h3:not(:first-of-type) {
  margin-top: calc(var(--gap) * 2.5);
}

label {
  position: relative;
  bottom: -68px;
  left: 10px;
  font-size: clamp(var(--clamp-xs-text));
}

.disclaimer {
  font-size: clamp(var(--clamp-xs-text));
  margin-top: var(--gap);
  width: min(100%, 525px);
}

.mathscript-form label span,
.disclaimer a {
  color: var(--white);
  background-color: var(--red);
  border-radius: var(--border-radius);
  padding-inline: 5px;
}

.disclaimer a {
  text-decoration: none;
  box-decoration-break: clone;
}

.submit-button {
  cursor: pointer;
  background-color: var(--red);
  color: var(--white);
  margin-top: calc(var(--gap) * 2);
  border-radius: var(--border-radius);
  width: min(400px, 100%);
  transition: var(--transition-short);
  padding: 1.5rem;
  border: none;
  outline: none;
  font-family: var(--standard-font);
  font-size: clamp(var(--clamp-small-text));
  font-weight: bold;
}

.submit-button:hover {
  background-color: var(--darkgrey);
}

.rate-us {
  background-color: var(--yellow);
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  margin-top: var(--gap);
  border: 3px solid var(--red);
}

.star-rate-us {
  margin-left: 0.5rem;
}

.star-rate-us-right {
  margin-inline: 0.5rem;
}
