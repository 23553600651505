.project-examples {
  width: 100%;
  margin-top: var(--margin-block-big);
}

.px-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: center;
  grid-gap: calc(var(--gap) * 1);
}

.px {
  width: 100%;
  aspect-ratio: 1/1;
  filter: drop-shadow(var(--drop-shadow-lighter));
  background-size: 55% 82%;
  background-position: center center;
  background-repeat: no-repeat;
  transition: var(--transition-short);
  position: relative;
}

.px:nth-of-type(1) {
  background-color: var(--sea);
  background-image: url("../files/wwwstudiomaja.jpg");
}

.px:nth-of-type(2) {
  background-color: var(--yellow);
  background-image: url("../files/wwwlucic.jpg");
}

.px:nth-of-type(3) {
  background-color: var(--pink);
  background-image: url("../files/wwwhouseemma.jpg");
}

.px:nth-of-type(4) {
  background-color: var(--red);
  background-image: url("../files/wwwdjb.jpg");
}

.px:nth-of-type(5) {
  background-color: var(--blue);
  background-image: url("../files/wwwapartmanisolin.jpg");
}

.px:nth-of-type(6) {
  background-color: var(--gold);
  background-image: url("../files/wwwnikolic.jpg");
}

.px:nth-of-type(7) {
  background-color: var(--green);
  background-image: url("../files/wwwmatella.jpg");
}

.px:nth-of-type(8) {
  background-color: var(--purple);
  background-image: url("../files/wwwrenata.png");
}

.px:hover {
  background-size: 60% 90%;
}

.px::before {
  content: attr(data-website);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--white);
  display: flex;
  justify-content: center;
  padding-block: 1rem;
  transition: var(--transition-short);
}

.px:hover::before {
  opacity: 0;
}

@media (max-width: 800px) {
  .px-container {
    grid-template-columns: 1fr;
  }
}
