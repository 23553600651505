.pp-text {
  width: 100%;
  padding-inline: var(--padding-inline-ten-percent);
  margin-bottom: var(--margin-block-big);
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  line-height: 1.4;
  overflow: hidden;
}

.pp-text h1 {
  font-size: clamp(var(--clamp-medium-text));
  width: min(100%, 750px);
}

.pp-text h1:not(:first-of-type) {
  margin-top: var(--gap);
}

.pp-text p {
  width: min(100%, 750px);
}

.pp-text span {
  color: var(--red);
  font-weight: bold;
}

.youtube-gdpr {
  width: min(100%, 750px);
  height: 500px;
  border-radius: var(--border-radius);
  filter: drop-shadow(var(--drop-shadow-lighter));
  margin-top: calc(var(--gap) * 2.5);
  margin-bottom: var(--gap);
}

.decour-link {
  text-decoration: none;
  color: var(--red);
  padding: 1.5rem 0rem;
}

.data-day {
  width: 100%;
  height: 375px;
  position: relative;
  background-color: var(--yellow);
  margin-top: var(--margin-block-big);
  border-radius: var(--border-radius);
}

.data-day img {
  position: absolute;
  top: -80px;
  left: 5%;
  height: 80%;
  aspect-ratio: 1/1;
  transform: rotate(8deg);
  border-radius: var(--border-radius);
}

.data-day img:nth-of-type(2) {
  bottom: -50px;
  left: 30%;
}

.data-day h3 {
  width: 50%;
  position: absolute;
  right: 30px;
  top: 10%;
  font-size: clamp(var(--clamp-medium-text));
  text-align: center;
  color: var(--pink);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius);
}

.data-day p {
  width: 100%;
  padding: 0.5rem 1.25rem;
  position: absolute;
  bottom: 0;
  font-size: clamp(var(--clamp-small-text));
  text-align: center;
  background-color: var(--purple);
  color: var(--white);
  border-radius: var(--border-radius);
}

@media (max-width: 950px) {
  .data-day {
    height: 475px;
  }

  .data-day h3,
  .data-day p {
    color: var(--white);
  }

  .data-day img {
    top: 5%;
    left: -20%;
  }
}

@media (max-width: 550px) {
  .data-day {
    height: 530px;
  }
  .data-day h3 {
    left: 0%;
    width: 80%;
  }
}

/* @media (max-width: 350px) {
  .data-day {
  }
} */
