.got-project {
  width: 100%;
  padding-inline: var(--padding-inline-ten-percent);
  display: grid;
  grid-gap: var(--gap);
  margin-bottom: var(--margin-block-big);
}

.got-project h1 {
  font-size: clamp(var(--clamp-medium-text));
}

.got-project p {
  width: min(100%, 800px);
}

.link-controller {
  text-decoration: none;
  color: var(--red);
  width: fit-content;
  font-weight: bold;
  transition: var(--transition-middle);
  position: relative;
  user-select: none;
}

.link-controller::before,
.link-controller::after {
  content: "";
  position: absolute;
  bottom: -7.5px;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: var(--red);
  transition: all 0.4s cubic-bezier(0.95, 0.05, 0.05, 0.95) 0s;
  transform: scaleX(0);
  transform-origin: center;
  overflow: hidden;
}

.link-controller::after {
  bottom: -15px;
  transition-delay: 0.2s;
  background-color: var(--black);
}

.link-controller:hover::before,
.link-controller:hover::after {
  transform-origin: 300%;
  transform: scaleX(1);
  opacity: 0.3;
}
