.App {
  width: 100%;
}

.splash {
  position: fixed;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 100000000;
  display: grid;
  place-content: center;
  place-items: center;
  color: var(--black);
  gap: 40px;
  transition: var(--transition-long);
}

.splash > * {
  user-select: none;
}

.splash img {
  filter: drop-shadow(0 0 30px white);
  border-radius: 100%;
  animation: whirl 4s linear infinite;
}

@keyframes whirl {
  to {
    transform: rotate(360deg);
  }
}

.splash h1 {
  font-size: clamp(var(--clamp-big-text));
  text-transform: uppercase;
  color: var(--red);
}

.splash h3 {
  font-size: clamp(var(--clamp-big-text));
  font-family: var(--cursive-font);
  border-block: black 3px solid;
  padding-block: 0.5rem;
  opacity: 0.7;
}

.splash.compress {
  transform: scale(0);
  opacity: 0;
}
