.some-benefits-over {
  font-size: clamp(var(--clamp-medium-text));
  font-weight: bold;
  padding-inline: var(--padding-inline-ten-percent);
}

.some-benefits {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: var(--margin-block-big);
  margin-top: calc(var(--gap) * 2.5);
  grid-gap: var(--gap);
  place-content: center;
  overflow: hidden;
}

.some-benefits-box {
  padding: var(--padding-inline-five-percent);
  font-size: clamp(var(--clamp-small-text));
  text-align: center;
  cursor: grabbing;
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap) * 0.5);
  justify-content: flex-start;
  align-items: center;

  height: 350px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius);
  transform: scaleY(0.8);
  transition: all 0.5s ease-in-out;
  transform-origin: top;

  overflow: hidden;
}

.some-benefits-box h3 {
  font-size: clamp(var(--clamp-medium-text));
  background-color: var(--yellow);
  padding: 0 0.5rem;
  border-radius: var(--border-radius);
}

.some-benefits-box span {
  font-size: clamp(var(--clamp-small-text));
  color: rgba(0, 0, 0, 0.5);
}

.some-benefits-box img {
  width: 100%;
  border-radius: var(--border-radius);
  object-fit: cover;
  object-position: center;
  user-select: none;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.some-benefits-box p {
  font-size: clamp(var(--clamp-small-text));
  color: rgba(0, 0, 0, 0.5);
  font-style: oblique;
  transform: scaleY(0);
  transition: all 0.5s ease-in-out;
  transform-origin: top;
}

.some-benefits-box:hover.some-benefits-box img,
.some-benefits-box:hover.some-benefits-box p {
  transform: scaleY(1);
}

.some-benefits-box:hover.some-benefits-box img {
  opacity: 1;
}

.some-benefits-box:hover.some-benefits-box {
  height: fit-content;
  transform: scaleY(1);
  background-color: var(--yellow);
}
