.cities-affiliate {
  width: 100%;
  margin-bottom: var(--margin-block-big);
  padding-inline: var(--padding-inline-ten-percent);
}

.cities-affiliate h1 {
  font-size: clamp(var(--clamp-medium-text));
  width: min(100%, 800px);
  margin-bottom: var(--gap);
}

.ca-container {
  display: flex;
  width: 100%;
}

.ca-container-img {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: var(--yellow);
  flex: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.ca-container-img::before {
  content: attr(data-city);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  background-color: rgba(0, 0, 0, 0.4);
  color: var(--white);
  display: grid;
  place-content: center;
  font-weight: bold;
  text-align: center;
  padding-inline: 5px;
}

.ca-container-img:first-of-type {
  background-image: url("../files/zagreb.jpeg");
}

.ca-container-img:nth-of-type(2) {
  background-image: url("../files/london.jpeg");
}

.ca-container-img:nth-of-type(3) {
  background-image: url("../files/stockholm.jpeg");
}

@media (max-width: 925px) {
  .ca-container {
    flex-direction: column;
  }

  .ca-container {
    width: 100%;
  }
}
