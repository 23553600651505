.careers-details {
  width: 100%;
  padding-inline: var(--padding-inline-ten-percent);
}

.cd-offer,
.cd-you-boxes,
.cd-conditions {
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
  margin-top: calc(var(--gap) * 2.5);
  margin-bottom: var(--margin-block-big);
}

.cd-offer-box {
  overflow: hidden;
}

.cd-offer-box img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
  border-radius: var(--border-radius);
  box-shadow: var(--drop-shadow-lighter);
}

.cd-offer-box h3,
.cd-offer-box p,
.cd-conditions-box h3,
.cd-conditions-box ul,
.cd-conditions-box p {
  margin-top: 1rem;
}

.cd-you {
  margin-top: calc(var(--gap) * 2.5);
}

.cd-you img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
  border-radius: var(--border-radius);
  box-shadow: var(--drop-shadow-lighter);
}

.cd-you-boxes h3 {
  margin-bottom: calc(var(--gap) * 0.25);
}

.cd-you-boxes {
  grid-gap: calc(var(--gap) * 2);
}

.cd-conditions-box img {
  height: 150px;
  object-fit: cover;
  justify-self: center;
}

.logo-career {
  user-select: none;
}

.cd-conditions-box ul {
  margin-bottom: var(--gap);
}

.cd-conditions-box ul li {
  padding-block: 0.5rem;
}

::marker {
  content: "\2601   ";
  margin-right: 1rem;
  color: var(--red);
}

.careers-details h4 {
  width: 100%;
  background-color: var(--yellow);
  padding: 1.25rem;
  border-radius: var(--border-radius);
  margin-top: calc(var(--gap) * 2);
}

.open-job-link {
  text-decoration: none;
  color: var(--black);
  background-color: var(--yellow);
  padding: 1.25rem;
  border-radius: var(--border-radius);
  transition: var(--transition-short);
  border: silver 3px solid;
  font-weight: bold;
}

.open-job-link:hover {
  opacity: 0.9;
  color: var(--grey);
}

.open-job-text {
  margin-top: calc(var(--gap) * 2);
  margin-bottom: calc(var(--gap) * 1.25);
  width: min(100%, 750px);
}
