.spreadmenu {
  position: fixed;
  right: 5%;
  top: 8%;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: calc(var(--gap) * 0.25);
  z-index: 10000000;
}

.spreadmenu h3 {
  font-size: clamp(var(--clamp-xs-text));
  color: var(--white);
  background-color: var(--red);
  transform: scaleX(0);
  transform-origin: right;
  opacity: 0;
  transition: var(--transition-middle);
  user-select: none;
  border-radius: var(--border-radius);
  padding: 0.25rem 1.75rem;
  border: var(--white) 2px solid;
}

.spreadmenu img,
.spreaded-content img {
  user-select: none;
  width: var(--spread-menu-size-50px);
  height: var(--spread-menu-size-50px);
  border-radius: 50%;
  transition: var(--transition-middle);
  /*   background-color: var(--white);
  border: var(--menu-ball-border);
  box-shadow: var(--drop-shadow-intro-images);
 */
  filter: drop-shadow(0 0 8px var(--white));
  animation: makeGrey 2s infinite;
}

@keyframes makeGrey {
  0%,
  100% {
    background-color: none;
  }

  50% {
    background-color: rgba(130, 130, 130, 0.15);
    transform: rotate(90deg);
  }

  70% {
    background-color: none;
  }
}

.spreadmenu img:hover {
  transform: scale(1.15);
}

.spreadmenu:hover.spreadmenu h3 {
  opacity: 1;
  transform: scale(1.2);
}

.spreaded-content img {
  position: fixed;
  right: 5%;
  top: 8%;
  transition: var(--transition-long);
}

.spreaded-content-layer-between {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  background-image: url("../files/universe.jpeg");
  background-color: rgb(132, 132, 132);
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;

  clip-path: circle(0% at top right);
  transition: var(--transition-long);
  transition-duration: 2.5s;

  z-index: 1000;
}

.spreaded-content-layer-between.show {
  clip-path: circle(150% at top right);
}

.spreaded-content-layer-between.show .spreaded-content img {
  transform: rotate(1080deg);
}

.spreaded-content {
  background-image: linear-gradient(var(--gradient));
  background-clip: text;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-inline: var(--padding-inline-twenty-percent);
  height: 100%;
  z-index: 1000;
}
.spreaded-content a {
  text-decoration: none;
  font-weight: bold;
  font-size: clamp(var(--clamp-medium-text));
  /*   color: transparent; */
  color: var(--white);
  transition: var(--transition-middle);
  user-select: none;
}

.spreaded-content a:hover {
  color: var(--yellow);
}

.english {
  color: transparent;
  height: 40px;
  width: 80px;
  background-image: url("../files/croatia.png");
  background-repeat: no-repeat;
  background-size: cover;
  border: solid 2px var(--white);
}

.spread-cont {
  position: fixed;
  inset: 0;
  background-color: white;
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  transform: scalex(0);
  opacity: 0;
  transform-origin: left top;
  transition: all 0.25s ease-in-out;
}

.spread-cont.blow {
  transform: scalex(1);
  opacity: 1;
}

.box {
  background-color: var(--red);
  height: 100%;
  width: 100%;
  transform: scaleY(0);

  transform-origin: center;
  transition: all 0.4s ease-out;
}

.box.blow {
  transition: all 0.4s ease-out;
}

.box.blow:nth-of-type(1) {
  transition-delay: 0.15s;
  transform: scaleY(1);
}

.box.blow:nth-of-type(2) {
  transition-delay: 0.3s;
  transform: scaleY(1);
}

.box.blow:nth-of-type(3) {
  transition-delay: 0.45s;
  transform: scaleY(1);
}

.box.blow:nth-of-type(4) {
  transition-delay: 0.6s;
  transform: scaleY(1);
}

.box.blow:nth-of-type(5) {
  transition-delay: 0.75s;
  transform: scaleY(1);
}

.box.blow:nth-of-type(6) {
  transition-delay: 0.9s;
  transform: scaleY(1);
}
.box.blow:nth-of-type(7) {
  transition-delay: 1.05s;
  transform: scaleY(1);
}

.real-cont {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  opacity: 0;
  transition: all 0.4s ease-out;

  background-image: url("../files/universe.jpeg");
  background-color: rgb(132, 132, 132);
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;

  padding: 1rem 5%;
  text-shadow: 0 0 4px black;
  pointer-events: none;
}

.real-cont.blow {
  opacity: 1;
  transition-delay: 0;
  transition-delay: 1.5s;
  pointer-events: all;
}

.real-cont img {
  width: min(50%, 200px);
  user-select: none;
  filter: drop-shadow(0 0 16px rgb(143, 143, 143));
  margin-bottom: 30px;
}

.real-cont h1 {
  font-size: clamp(var(--clamp-medium-text));
  color: var(--white);
  margin-bottom: 12px;
  letter-spacing: 3px;
}

.real-cont h3 {
  font-size: clamp(var(--clamp-before-text));
  color: var(--white);
  font-family: var(--cursive-font);
  border-block: 4px solid var(--yellow);
  width: fit-content;
  padding-block: 0.5rem;
  margin-bottom: 50px;
}

.org-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}

.org-link a {
  text-decoration: none;
  color: var(--white);
  font-weight: bold;
  font-size: clamp(var(--clamp-small-text));
  transition: var(--transition-middle);
  padding: 0.25rem 0.75rem;
}

.org-link a:hover {
  color: var(--red);
  background-color: var(--white);
  border-radius: var(--border-radius);
  text-shadow: none;
}

.org-link .special {
  color: var(--red);
  background-color: var(--white);
  text-shadow: none;
  padding: 0.5rem 3rem;
  text-transform: uppercase;
  border-radius: 5px;
}

@media (max-width: 550px) {
  .spreadmenu img,
  .spreaded-content img {
    width: var(--spread-menu-size-40px);
    height: var(--spread-menu-size-40px);
  }

  .spreaded-content {
    align-items: flex-start;
    padding-inline: var(--padding-inline-ten-percent);
  }

  .spreadmenu:hover.spreadmenu h3 {
    transform: scale(1);
  }
}

@media (max-width: 310px) {
  .spreadmenu:hover.spreadmenu h3 {
    transform: scale(0.9);
    text-align: center;
  }
}