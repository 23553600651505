.error {
  width: 100%;
}

.error-handler {
  overflow-wrap: anywhere;
  padding-inline: var(--padding-inline-ten-percent);
  margin-bottom: var(--margin-block-big);
  text-align: justify;
  height: fit-content;
}

.error-handler span {
  color: var(--red);
  transition: var(--transition-middle);
  font-style: oblique;
}

.error-handler:hover.error-handler span {
  opacity: 0.6;
}

.error-handler h3 {
  margin-bottom: calc(var(--gap) * 2);
}

.error-handler a {
  text-decoration: none;
}

.bright-text {
  background-image: url("../files/universe.jpeg");
  background-size: 150%;
  background-position: center;
  background-clip: text;
  color: transparent;
  margin-top: calc(var(--gap) * 3);
  font-family: var(--cursive-font);
  font-size: clamp(var(--clamp-cursive-text));
  user-select: none;
  padding: 1rem;
  animation: moveBackgroundError 30s linear infinite alternate;
  padding-inline: var(--padding-inline-ten-percent);
  text-align: center;
}

@keyframes moveBackgroundError {
  0% {
    background-position: 30% 30%;
  }

  100% {
    background-position: 65% 65%;
  }
}
