.navigation,
.small-navigation {
  width: 75%;
  height: 40px;
  display: flex;
  justify-content: center;
  margin: auto;
  user-select: none;
  font-size: clamp(var(--clamp-xs-text));
}

.navigation a,
.small-navigation p {
  text-decoration: none;
  color: white;
  background-color: var(--red);
  background-color: rgba(216, 32, 32, 0.7);
  padding: 0 var(--gap);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition-long);
  overflow: hidden;
  position: relative;
  z-index: 500;
  border-bottom: white 4px solid;
}

.navigation a:first-of-type,
.small-navigation p:first-of-type {
  border-bottom-left-radius: var(--border-radius);
}

.navigation a:last-of-type,
.small-navigation p:last-of-type {
  background-color: var(--black);
  border-bottom-right-radius: var(--border-radius);
}

.navigation a::before,
.small-navigation p::before {
  content: attr(data-link);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: -1%;
  width: 101%;
  height: 100%;
  background-color: var(--white);
  transform: scaleY(0);
  transition: var(--transition-middle);
  transform-origin: bottom;
}

.navigation a:hover::before,
.small-navigation p:hover::before {
  transform: scaleY(1.05);
  color: var(--black);
  font-weight: bold;
}

.small-navigation {
  display: none;
}

@media (max-width: 550px) {
  .navigation {
    display: none;
  }

  .small-navigation {
    display: flex;
  }
}
