.blog-home {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--margin-block-big);
}

.blog-home h1 {
  font-size: clamp(var(--clamp-medium-text));
  padding-inline: var(--padding-inline-ten-percent);
  background-color: var(--red);
  background-color: white;
  color: var(--white);
  color: black;
  padding-block: calc(var(--gap) * 0.5);
}

.blog-home p {
  font-size: clamp(var(--clamp-small-text));
  padding-inline: var(--padding-inline-ten-percent);
  padding-right: 400px;
  padding-block: calc(var(--gap) * 0.5);
  background-color: var(--white);
  color: var(--darkgrey);
}

.blog-home-container {
  background-color: var(--white);
  padding: 0;
  padding-inline: var(--padding-inline-ten-percent);
  padding-block: var(--padding-inline-five-percent);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: var(--gap);
}

.blog-home-container-box {
  filter: drop-shadow(var(--drop-shadow-lighter));
  border-radius: var(--border-radius);
  overflow: hidden;
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: var(--padding-inline-five-percent);
}

.blog-home-container-box:first-of-type {
  background-image: url("../files/first-domain.jpeg");
}

.blog-home-container-box:nth-of-type(2) {
  background-image: url("../files/design-a.jpeg");
}

.blog-home-container-box:nth-of-type(3) {
  background-image: url("../files/mathboard.jpeg");
}

.blog-home-container-box::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30%;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.2)
  );
  transition: var(--transition-middle);
}

.blog-home-container-box:hover::before {
  height: 50%;
}

.blog-home-container-box a {
  text-decoration: none;
  color: var(--white);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--padding-inline-five-percent);
  font-size: clamp(var(--clamp-small-text));
  font-weight: bold;
}

.blog-home-line {
  width: 100%;
  background-color: var(--red);
  padding-block: 20px;
  padding-inline: var(--padding-inline-ten-percent);
  color: var(--white);
  font-size: clamp(var(--clamp-xs-text));
}

@media (max-width: 1000px) {
  .blog-home p {
    padding-right: 200px;
  }
}

@media (max-width: 750px) {
  .blog-home p {
    padding-right: var(--padding-inline-ten-percent);
  }
}
