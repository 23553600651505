.list-description {
  width: 100%;
  padding-inline: var(--padding-inline-ten-percent);
  margin-bottom: var(--margin-block-big);
}

.list-description h1 {
  margin-bottom: var(--gap);
  font-size: clamp(var(--clamp-medium-text));
}

.list-description-box {
  display: flex;
  flex-direction: row;
  gap: var(--gap);
}

.list-description-box-1 {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  gap: calc(var(--gap) * 0.5);
}

.list-description-box-1 li {
  list-style: none;
  border-bottom: solid 3px rgb(216, 216, 216);
  padding-block: calc(var(--gap) * 0.25);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  transition: var(--transition-middle);
  border-radius: var(--border-radius);
  padding-inline: 0.5rem;
}

.list-description-box-1 li:nth-of-type(odd) {
  background-color: rgb(214, 214, 214);
}

.list-description-box-2 {
  flex: 1;
}

.list-description-box-2 img {
  height: 425px;
  user-select: none;
}

@media (max-width: 925px) {
  .list-description-box {
    flex-direction: column;
    gap: calc(var(--gap) * 2);
  }

  .list-description-box-2 img {
    width: 100%;
  }
}
