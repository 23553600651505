.sd {
  width: 100%;
  padding-inline: var(--padding-inline-ten-percent);
  margin-bottom: var(--margin-block-big);
  box-sizing: border-box;
}

.services-div {
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap) * 3);
  margin-top: var(--margin-block-big);
  width: 100%;
}

.services-div-box {
  width: 100%;
  display: flex;
  gap: var(--gap);
}

.services-img {
  width: 25%;
  aspect-ratio: 1/0.75;
  border-radius: var(--border-radius);
  box-shadow: var(--drop-shadow-lighter);
  flex: 1;
  object-fit: cover;
  object-position: center;
  transition: var(--transition-short);
}

.services-img:hover {
  filter: sepia(0.8);
}

.inner-div,
.inner-div2 {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
}

.first-p {
  font-size: clamp(var(--clamp-medium-text));
  font-weight: bold;
}

.second-p {
  padding-inline: var(--padding-inline-five-percent);
}

.sd-different {
  width: min(100%, 800px);
  margin-top: calc(var(--gap) * 2);
  background-color: var(--yellow);
  padding: 1rem;
  border-radius: var(--border-radius);
}

.sd-list {
  width: min(100%, 675px);
  margin-top: calc(var(--gap) * 1);
}

.sd-list li {
  margin-bottom: calc(var(--gap) * 0.35);
  padding: 0.5rem;
}

.sd-list li:nth-of-type(even) {
  background-color: rgb(229, 229, 229);
  border-radius: var(--border-radius);
}

.sd h4 {
  width: 100%;
  background-color: var(--yellow);
  padding: 1.25rem;
  border-radius: var(--border-radius);
  margin-top: calc(var(--gap) * 2);
}

.sd h4 a {
  text-decoration: none;
  color: var(--darkgrey);
}

.coffee {
  width: min(550px, 85%);
  margin-inline: auto;
  height: auto;
  overflow: hidden;
}

.coffee img {
  width: 100%;
  user-select: none;
}

.sd-benefits-info {
  font-size: clamp(var(--clamp-small-text));
  font-weight: bold;
  width: min(100%, 800px);
  text-align: justify;
  border-bottom: var(--form-grey) 3px solid;
  margin-bottom: var(--gap);
}

.sd-benefits-info span {
  font-weight: normal;
  font-size: clamp(var(--clamp-xs-text));
  line-height: 1;
  margin-bottom: var(--gap);
  font-style: oblique;
}

.sd-benefits-info::first-letter {
  font-size: clamp(var(--clamp-big-text));
  font-weight: bold;
  color: var(--white);
  background-color: var(--red);
}

@media (max-width: 850px) {
  .services-div-box {
    flex-direction: column;
  }

  .services-img {
    width: 80%;
    margin-inline: auto;
    order: 2;
  }

  .inner-div {
    order: 1;
  }

  .services-div {
    gap: calc(var(--gap) * 3.5);
  }
}

@media (max-width: 550px) {
  .services-img {
    width: 100%;
  }
}
