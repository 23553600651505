.introduction-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: var(--margin-block-big);
}

.introduction-box h1 {
  font-size: clamp(var(--clamp-medium-text));
  width: min(85%, 800px);
}

.introduction-box h3 {
  font-size: clamp(var(--clamp-small-text));
  width: min(90%, 600px);
  font-weight: 400;
  font-weight: bold;
}

.introduction-box a {
  text-decoration: none;
  background-color: var(--black);
  color: var(--white);
  border-radius: var(--border-radius);
  padding: 0.75rem 3rem;
  width: min(100%, 400px);
  transition: var(--transition-middle);
  font-weight: bold;
  filter: drop-shadow(var(--drop-shadow-silver));
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-block: 30px;
}

.introduction-box a::before {
  content: "Click and go";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--red);
  border-radius: var(--border-radius);
  transform: scaleX(0);
  transition: var(--transition-short);
  transform-origin: right;
  display: grid;
  place-content: center;
}
.introduction-box a:hover::before {
  transform: scaleX(1);
}
