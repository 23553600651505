.about-details {
  width: 100%;
  padding-inline: var(--padding-inline-ten-percent);
  margin-bottom: var(--margin-block-big);
  overflow: hidden;
}

.goals {
  margin-top: calc(var(--gap) * 2);
  width: min(100%, 800px);
  transform: perspective(200px) rotateY(3deg) rotateX(0deg) translateX(-50px);
  transform-style: preserve-3d;
  transform-origin: center;
  box-shadow: 0 0 80px 5px rgb(104, 104, 104);
  padding: 2rem 2rem;
  position: relative;
  height: max-content;
  transform-style: preserve-3d;
}

.goals::before,
.goals::after {
  content: "";
  position: absolute;
  inset: 0;
  width: fit-content;
  height: 110%;
  transform: translateZ(-25px) translateX(-200px);
  transform-origin: center;
  perspective: 400px;
  z-index: -1;
  border-radius: 50px;
}

.goals::after {
  z-index: -2;
  background-color: white;
  transform: translateZ(-35px) translateX(-160px);
  transform-origin: center;
  border-radius: var(--border-radius);
}

.goals li {
  margin-bottom: calc(var(--gap) * 0.5);
  font-weight: bold;
  margin-left: 1rem;
  padding: 0.25rem 1rem;
  border-radius: var(--border-radius);
}

.goals li:nth-of-type(3n) {
  background-color: var(--darkgrey);
  color: var(--white);
}
.goals li:nth-of-type(5n - 3) {
  background-color: var(--red);
  color: var(--white);
}

.ad-last-why {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: var(--margin-block-big);
  margin-top: calc(var(--gap) * 2);
  gap: var(--gap);
}

.ad-last-why-box {
  padding: 1.5rem;
  text-align: center;
  color: var(--white);
  background-blend-mode: soft-light;
  border-radius: var(--border-radius);
}

.ad-last-why-box p {
  margin-top: calc(var(--gap) * 0.5);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: var(--border-radius);
  padding-inline: 0.25rem;
}

.ad-last-why-box:first-of-type {
  background-color: var(--sea);
  background-image: url("../files/wall.jpeg");
}

.ad-last-why-box:nth-of-type(2) {
  background-color: var(--red);
  background-image: url("../files/wall.jpeg");
}
.ad-last-why-box:nth-of-type(3) {
  background-color: var(--gold);
  background-image: url("../files/wall.jpeg");
}
.ad-last-why-box:nth-of-type(4) {
  background-color: var(--pink);
  background-image: url("../files/wall.jpeg");
}

.about-info-cro {
  padding: 2rem;
  background-color: var(--darkgrey);
  margin-top: 100px;
}

.about-info-eng img {
  width: min(250px, 100%);
  margin-block: var(--gap);
}

.about-info-eng a,
.about-info-cro a {
  text-decoration: none;
  color: var(--darkgrey);
  cursor: auto;
}

.about-info-cro a {
  color: var(--white);
}

.about-info-eng a p,
.about-info-cro a p {
  padding-bottom: calc(var(--gap) * 0.5);
  width: min(100%, 625px);
}

.first-link {
  padding-top: var(--gap);
}

.about-info-eng span {
  color: var(--red);
  font-weight: bold;
}

.about-info-cro span {
  color: var(--yellow);
  font-weight: bold;
}

.remove-padding {
  margin-inline: -5%;
}

.about-details .services-img {
  box-shadow: none;
  width: min(75%, 300px);
  aspect-ratio: 1/0.5;
  object-fit: contain;
}

@media (max-width: 925px) {
  .ad-last-why {
    grid-template-columns: 1fr;
  }
}
