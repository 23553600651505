.contact-more-data {
  width: 100%;
  margin-block: var(--margin-block-big);
  padding-inline: var(--padding-inline-ten-percent);
}

.more-contacts {
  width: 100%;
  margin-top: calc(var(--gap) * 2);
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--gap) * 2);
  justify-content: center;
}

.more-contacts a {
  text-decoration: none;
  display: grid;
  place-items: center;
  grid-gap: calc(var(--gap) * 0.5);
}

.more-contacts p {
  font-size: clamp(var(--clamp-xs-text));
  color: var(--grey);
  text-align: center;
}

.more-contact-icon {
  color: var(--black);
  transform: scale(3);
  margin-bottom: calc(var(--gap) * 0.5);
  transition: var(--transition-short);
}

.more-contacts a:hover .more-contact-icon {
  color: var(--red);
}

.more-contacts a:hover .more-contacts p {
  color: var(--darkgrey);
}
