.footer-main {
  background-color: var(--black);
  color: white;
  height: fit-content;
  box-shadow: var(--drop-shadow-lighter);
  margin-top: 160px;
  padding: 80px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  user-select: auto;
  border-bottom: var(--red) solid 30px;
  overflow: hidden;
}

.set100 {
  width: 100%;
}

.footer-rect {
  width: 100%;
  text-align: end;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-rect a {
  color: white;
  text-decoration: none;
  transition: var(--transition-middle);
  font-size: clamp(var(--clamp-small-text));
}

.footer-rect a:first-child {
  margin-top: 20px;
}

.footer-rect a:hover {
  color: var(--red);
}

.btc-img {
  width: 50%;
  margin-left: auto;
  border-radius: 20px;
  user-select: none;
  opacity: 0.85;
  border: 2px solid var(--white);
}

.rect-one,
.rect-two {
  border-right: var(--red) dashed 2px;
}

.rect-one,
.rect-three {
  user-select: none;
}

.rect-one p {
  font-size: clamp(var(--clamp-xs-text));
  color: var(--lightred);
  font-style: italic;
  margin-left: 50%;
}

.rect-two {
  user-select: initial;
  pointer-events: none;
}

.rect-two p {
  margin: 0;
  padding: 0;
  font-size: clamp(var(--clamp-xs-text));
}

.rect-two span {
  font-weight: 700;
  font-style: italic;
  padding: 0px 8px;
  color: var(--lightred);
}

.rect-two a {
  margin-left: 40px;
  user-select: auto;
}

.cr {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding-block: 0.5rem;
}

.cr p {
  font-size: clamp(var(--clamp-xs-text));
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
}

.icon-red {
  color: var(--red);
  margin-left: 10px;
}

.rect-three {
  gap: 20px;
}

.rect-three a {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 10px;
}

.rect-three a p {
  margin-right: 10px;
}

.rect-three img {
  width: 70%;
  justify-self: flex-end;
  margin-left: 30%;
  user-select: none;
  border-radius: var(--border-radius);
  margin-top: calc(var(--gap) * 2);
  box-shadow: var(--drop-shadow-intro-images);
}

.rect-three > h3 {
  font-size: clamp(var(--clamp-small-text));
  width: min(80%, 300px);
  text-align: center;
  margin-left: auto;
}

.logo-footer {
  border: none;
  filter: drop-shadow(var(--drop-shadow-intro-images));
  margin-top: calc(var(--gap) * 1.25);
  max-width: 200px;
  align-self: center;
}

.logo-footer-text {
  width: min(80%, 300px);
  align-self: end;
}

@media (max-width: 1000px) {
  .footer-main {
    display: grid;
    grid-template-areas: "rect-one rect-three" "rect-two rect-two";
    height: fit-content;
  }

  .footer-rect {
    padding-right: 10px;
    width: 100%;
  }

  .rect-one {
    grid-area: rect-one;
    border-right: var(--red) dashed 2px;
    padding-right: 30px;
  }

  .rect-two {
    grid-area: rect-two;
    margin-top: 60px;
    border-top: var(--red) dashed 2px;
    padding-top: 30px;
    text-align: center;
  }

  .rect-three {
    grid-area: rect-three;
  }

  .rect-three,
  .rect-two {
    border-right: var(--black) dashed 0px;
  }

  .logo-footer-text {
    align-self: center;
  }

  .logo-footer {
    width: min(80%, 300px);

    place-self: center;
    margin-inline: 10%;
  }
}

@media (max-width: 768px) {
  .rect-one {
    border-right: var(--red) dashed 0px;
  }

  .rect-three a {
    margin-right: 20px;
    margin-left: 20px;
  }
  .rect-three a p {
    margin-left: 20px;
  }

  .rect-two a {
    text-align: center;
    padding: 0;
    margin-inline: calc(var(--gap) * 2);
  }

  .cr {
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .footer-main {
    display: grid;
    grid-template-areas: "rect-one" "rect-three" "rect-two";
    height: fit-content;
  }

  .rect-one {
    padding-right: 0px;
  }

  .rect-one,
  .rect-two,
  .rect-three {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .rect-three {
    border-top: var(--red) dashed 2px;
    margin-top: 60px;
    padding-top: 30px;
  }

  .rect-two a {
    margin-inline: var(--gap);
  }

  .btc-img {
    margin: auto;
  }

  .rect-three img {
    margin-inline: auto;
  }

  .rect-three > h3 {
    margin-inline: auto;
    text-align: center;
  }

  .btc-text {
    transform: translateX(-50%);
  }

  .cr {
    justify-content: space-around;
    text-align: center;
    padding: 20px 0 10px 0;
    margin-inline: 30px;
    gap: 5px;
  }
}
